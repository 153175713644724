<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <h3 class="mb-4">줌 참가 안내</h3>
              <!-- <div class="row align-items-center">
                <div class="col">
                  <ul class="list-group">
                    <li class="list-group-item">
                      <a href="https://youtu.be/XO99buIOfX0" target="_blank"
                        >줌 사전 안내 영상 보기 https://youtu.be/XO99buIOfX0</a
                      >
                    </li>
                    <li class="list-group-item">
                      줌입장하기를 클릭했을때 아이디 입력 요구 시,
                      회의ID(<strong>{{ zoomString }}</strong
                      >)를 입력해주세요!!
                    </li>
                  </ul>
                </div>
              </div> -->
              <div class="text-center mt-4">
                <base-button outline type="warning" icon @click="goMeet"
                  ><span class="btn-inner--icon"
                    ><i class="ni ni-bell-55 text-orange"></i
                  ></span>
                  <span class="btn-inner--text">[줌(Zoom) 입장하기]</span>
                </base-button>
                <br /><br />
              </div>
            </div>
            <!-- End Search, New Button -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import { ref } from "vue";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
  },
  // 데이터 객체 추가
  setup() {
    const url = ref("");
    const zoomString = ref("");

    // api 호출
    const getDataFromApi = () => {
      const token = api.Auth.tokenContent;
      const url2 = `api/service/class2/zoom?grade=${token.grade}`;
      api.get(url2).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        if (data.rows > 0) {
          url.value = data.data[0].url;
          let temp = url.value.split("/");
          zoomString.value =
            temp[4].substr(0, 3) +
            " " +
            temp[4].substr(3, 4) +
            " " +
            temp[4].substr(7, 4);
        }
        if (url.value) {
          window.open(url.value);
        }
      });
    };
    getDataFromApi();
    const goMeet = () => {
      if (url.value) {
        window.open(url.value);
      }
    };
    return {
      url,
      zoomString,
      goMeet,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
img {
  width: 100%;
}
</style>
