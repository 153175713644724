<!-- 공지 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6 bg-success">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 title">실천활동 자료실</h3>
                </div>
              </div>
            </div>
            <div class="card-body">
              <table align="center" width="100%">
                <ul class="list-group list-group-flush list my--3">
                  <li
                    v-for="row in tableData"
                    :key="row.id"
                    class="list-group px-0"
                  >
                    <tr>
                      <td class="list-group-item px-2">
                        <div class="row pl-4">
                          <div class="ml-0">
                            <b>{{ row.title }}</b>
                          </div>
                        </div>
                        <hr />
                        <div>
                          <a :href="row.url" target="_blank"
                            ><button
                              type=""
                              class="btn btn-primary"
                              v-if="row.urlName"
                            >
                              {{ row.urlName }} 보기
                            </button></a
                          >
                          <br /><br />
                          <a :href="row.url2" target="_blank"
                            ><button
                              type=""
                              class="btn btn-primary"
                              v-if="row.urlName2"
                            >
                              {{ row.urlName2 }} 보기
                            </button></a
                          >
                          <br /><br />
                          <a :href="row.url3" target="_blank"
                            ><button
                              type=""
                              class="btn btn-primary"
                              v-if="row.urlName3"
                            >
                              {{ row.urlName3 }} 보기
                            </button></a
                          >
                          <br /><br />
                          <div v-html="row.post"></div>
                        </div>
                      </td>
                    </tr>
                    <br />
                  </li>
                </ul>
              </table>
              <!-- Pagination -->
              <div class="card-footer py-4 d-flex justify-content-center">
                <common-pagination
                  @page-change="getDataFromApi"
                  :rows-per-page="perPage"
                  :total="total"
                />
              </div>
              <!-- End Pagination -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import moment from "moment";
import { Auth } from "@/api";
import { ref } from "vue";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
  },
  // 데이터 객체 추가
  setup() {
    const tableData = ref([]); // 레코드셋
    const perPage = ref(20); // 페이지당 레코드 수
    const total = ref(0); // 총 레코드 수
    // api 호출
    const getDataFromApi = (page = 1) => {
      const token = Auth.tokenContent;
      const url = `api/service/class2/weekend?page=${page}&grade=${token.grade}&semester=${token.semester}&year=${token.year}`;
      api.get(url).then((response) => {
        if (!api.isSuccess(response)) {
          return;
        }
        const data = response.data;
        const d = data.data;
        if (data.rows > 0) {
          for (const row of d) {
            row.start = moment(row.start).locale("ko").format("M월 D일(dd)");
            row.end = moment(row.end).locale("ko").format("M월 D일(dd)");
            row.createdTime = moment(row.createdTime).format("YYYY-MM-DD");
          }
        }
        tableData.value = d;
        total.value = data.total;
      });
    };
    getDataFromApi();
    return {
      tableData, // 레코드셋
      perPage, // 페이지당 레코드 수
      total, // 총 레코드 수
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
