<!-- 조별 주소 목록 페이지 -->
<template>
  <div>
    <base-header class="pb-6 bg-success">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <!-- Route -->
            <route-bread-crumb></route-bread-crumb>
            <!-- End Route -->
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <!--Tables-->
      <div class="row">
        <div class="col-xl">
          <div class="card">
            <!-- Search, New Button -->
            <div class="card-header border-0">
              <h3 class="mb-4">법륜스님 즉문즉설</h3>
              <!-- <div class="row align-items-center">
                <div class="col">
                  <ul class="list-group">
                    <li class="list-group-item" v-if="buldae">
                      <img :src="require('@/assets/etiquette3.jpg')" />
                    </li>
                    <li class="list-group-item" v-else>
                      <img :src="require('@/assets/talk2.png')" />
                    </li>
                  </ul>
                </div>
              </div> -->
              <div class="text-center mt-4">
                <base-button outline type="warning" icon @click="goMeet"
                  ><span class="btn-inner--icon"
                    ><i class="ni ni-bell-55 text-orange"></i
                  ></span>
                  <span class="btn-inner--text">[즉문즉설 참여하기]</span>
                </base-button>
                <br /><br />
                <div>지금 한국 시간 : {{ now2 }} <br /><br /></div>
                <div>
                  수업 시간 : {{ lessonStart2 }} (한국시간기준) <br /><br />
                </div>
              </div>
            </div>
            <!-- End Search, New Button -->
          </div>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>
</template>

<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as api from "@/api";
import moment from "moment-timezone";
import { Auth } from "@/api";
import { ref, onUpdated } from "vue";

export default {
  // 컴포넌트 초기화
  components: {
    RouteBreadCrumb,
  },
  // 데이터 객체 추가
  setup() {
    const url = ref("");
    const isClassDay = ref(0);
    const lessonNo = ref(0);
    const verboseStudent = ref(false);
    const lessonName = ref("");
    const lessonDate = ref("");
    const lessonStart = ref("");
    const section = ref("");
    const isAttend = ref(false);
    const buldae = ref(false);
    const lessonStart2 = ref("");
    const now2 = ref("");

    // api 호출
    const getDataFromApi = () => {
      const token = Auth.tokenContent;
      verboseStudent.value = token.role.includes("student");

      buldae.value = token.grade.includes("불교대학");
      const url1 = `api/service/class2/talk?today=1&grade=${token.grade}&semester=${token.semester}&year=${token.year}`;
      api.get(url1).then((response1) => {
        if (!api.isSuccess(response1)) {
          return;
        }
        const data1 = response1.data;
        if (data1.rows > 0) {
          const url2 = `api/service/class2/meet?grade=${token.grade}&semester=${token.semester}&year=${token.year}&local=${token.local}&room=${token.room}&team=${token.team}`;
          api.get(url2).then((response) => {
            if (!api.isSuccess(response)) {
              return;
            }
            const data = response.data;
            url.value = data.data[0].url;
            moment.tz.setDefault("Asia/Seoul");

            var xmlHttpRequest;
            if (window.XMLHttpRequest) {
              // code for Firefox, Mozilla, IE7, etc.
              xmlHttpRequest = new XMLHttpRequest();
            } else {
              return;
            }
            xmlHttpRequest.open("HEAD", window.location.href.toString(), false);
            xmlHttpRequest.setRequestHeader("ContentType", "text/html");
            xmlHttpRequest.send("");
            var serverDate = xmlHttpRequest.getResponseHeader("Date");
            let now = moment(serverDate);

            //하드코드
            //now = moment("2023-05-06 13:45");

            now2.value = moment(now).format("YYYY-MM-DD HH:mm:ss");

            if (data1.data[0].title === "즉문즉설1") {
              lessonStart.value = moment(data.data[0].talkStartTime1);
            } else if (data1.data[0].title === "즉문즉설2") {
              lessonStart.value = moment(data.data[0].talkStartTime2);
            } else if (data1.data[0].title === "즉문즉설3") {
              lessonStart.value = moment(data.data[0].talkStartTime3);
            } else if (data1.data[0].title === "즉문즉설4") {
              lessonStart.value = moment(data.data[0].talkStartTime4);
            }
            let attendStart = moment(lessonStart.value).add(-20, "minutes");
            let attendEnd = moment(lessonStart.value).add(1, "minutes");
            let lateEnd = moment(lessonStart.value).add(16, "minutes");
            let lessonEnd = moment(lessonStart.value).add(3, "hours");

            lessonStart2.value = moment(lessonStart.value).format(
              "YYYY-MM-DD HH:mm"
            );

            if (now >= attendStart && now < attendEnd) {
              isClassDay.value = 1;
              isAttend.value = true;
            } else if (now >= attendEnd && now < lateEnd) {
              isClassDay.value = 1;
              isAttend.value = false;
            } else if (now >= lateEnd && now < lessonEnd) {
              isClassDay.value = 2;
            } else {
              isClassDay.value = 0;
            }
            if (verboseStudent.value === false) {
              isClassDay.value = 2;
            }
            if (isClassDay.value == 1) {
              lessonName.value = data1.data[0].title;
              lessonNo.value = data1.data[0].lessonNo;
              section.value = data1.data[0].section;
              if (!isAttend.value) {
                const url3 = `api/service/class2/attend2?memberNo=${token.memberNo}`;
                api.get(url3).then((response3) => {
                  if (!api.isSuccess(response3)) {
                    return;
                  }

                  if (
                    eval(
                      "response3.data.data[0].talkLesson" +
                        lessonNo.value +
                        ".attend"
                    ) === 0
                  ) {
                    isAttend.value = true;
                  }
                });
              }
            }
          });
        } else {
          isClassDay.value = 0;
        }
      });
    };
    getDataFromApi(1);
    const goMeet = () => {
      // getDataFromApi(1);
      const data = {};
      if (!verboseStudent.value) {
        window.open(url.value);
      } else if (isClassDay.value == 0) {
        alert("수업시간이 아닙니다.");
      } else {
        if (url.value && (!isAttend.value || isClassDay.value === 2)) {
          window.open(url.value);
        }

        if (isAttend.value && verboseStudent.value && isClassDay.value === 1) {
          data.lessonStart = lessonStart.value;
          data.lessonType = "talk";

          api
            .put(`api/service/class2/attend2/${lessonNo.value}`, { data })
            .then((response) => {
              if (!api.isSuccess(response)) {
                return;
              }
              const data2 = response.data;
              if (data2.msg === "ok") {
                isAttend.value = false;
                window.open(url.value);
              } else {
                alert("수업시간이 아닙니다....");
              }
            });
        }
      }
    };

    // setTimeout(() => {
    //   goMeet();
    // }, 1000);
    onUpdated(() => goMeet());
    return {
      url,
      isClassDay,
      lessonNo,
      verboseStudent,
      lessonName,
      lessonDate,
      lessonStart,
      section,
      isAttend,
      buldae,
      lessonStart2,
      now2,
      goMeet,
    };
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
img {
  width: 100%;
}
</style>
